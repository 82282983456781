<template>
  <v-app>
    <v-app-bar
      app
      color="darken-2"
    >
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <v-dialog v-model="dialog" width="512">
          <v-img :src="openSrc"></v-img>
          <v-card>
            <v-card-text class="pt-4">
              {{ openPrompt }}
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-row no-gutters>
          <v-col
            v-for="image, n in images"
            :key="n"
            class="d-flex child-flex"
            cols="3"
          >
            <v-img
              :src="image.src"
              class="app-img grey lighten-2"
              @click="openImage(image.src)"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  components: {},
  created() {
    fetch('./images.txt')
    .then(res => res.text())
    .then(res => {
      const lines = res.split("\n");
      this.images = lines
        .reverse()
        .filter(i => i !== "")
        .map(i => ({ src: i.split(" ")[1] }))
      console.log(this.images);
    })
  },
  data: () => ({
    images: [],
    dialog: false,
    openSrc: "",
    openPrompt: "",
    groupPrompt: {},
  }),
  methods: {
    openImage(src) {
      console.log(src);
      this.openSrc = src;
      this.dialog = true;
      const group = src.split('/')[2];
      fetch(`./img/${group}/prompt.txt`)
        .then(res => res.text())
        .then(res => {
          this.openPrompt = res
        })
    }
  },
};
</script>

<style>
  .app-img {
    cursor: pointer;
  }
</style>
